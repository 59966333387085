import moment from "moment";

import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { FieldGroup, Dictionary, FormValues } from 'components/Common/Interfaces/Entity.interface';
import { ProfileState } from "components/Profile/Interfaces/ProfileState.interface";
import { BasePermitToWork, ConfinedSpacesPTW, HotWorksPTW, LimitationOfAccessPTW, WorkPermitType, WorkingAtHeightPTW } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { FieldMeta, Primitive } from 'components/Common/Interfaces/Entity.interface';
import { SearchParams } from "components/Common/Components/JAAutoComplete/JAAutoComplete";
import { Option } from "components/Common/Interfaces/Entity.interface";
import { CommonValidator, isEmpty } from "store/Common/Helpers/commonHelpers";

import { searchForPermittedPermitStaff } from "components/AdminPanel/People/Services/peopleServices"

export const getPermittedStaffValue = (option: any) => option;
export const getPermittedStaffOptionSelected = (option: any, value: any) => option.id == value.id;

const getPermittedStaffLabel = (option: any) => {
    if (option?.label) {
        return `${option.label}`;
    }
    return `${option}`;
};

export type PtwType = BasePermitToWork | HotWorksPTW | WorkingAtHeightPTW | LimitationOfAccessPTW | ConfinedSpacesPTW;

interface GetPTWFormFieldsConfigProps {
    data: BasePermitToWork | HotWorksPTW | WorkingAtHeightPTW | LimitationOfAccessPTW | ConfinedSpacesPTW;
    canWrite: boolean;
    permitType: WorkPermitType;
    currentUser: ProfileState;
    scheduledFor?: string;
    formValuesRef: React.MutableRefObject<any>;
}

const validateNominatedPersonFieldTogether: CommonValidator = ({ formValuesRef, value, meta }) => {
    const thisValidator = (value: Primitive) => {
        let otherFieldHasValue = false;
        const fieldsToCheck = [
            'nominated_person_first_name',
            'nominated_person_last_name',
            'nominated_person_email',
            'nominated_person_contact_number',
            'nominated_person_position'
        ]
        if (
            isEmpty(value)
        ) {
            fieldsToCheck.forEach(x => {
                if (!isEmpty(formValuesRef.current[x])) {
                    otherFieldHasValue = true;
                }
            })
        }
        return otherFieldHasValue ? `If any nominee details are filled out, ${meta.label} must have a value` : undefined;
    }
    return {
        //"inputValidations": thisValidator(value),
        //"save": thisValidator(value),
        "submit": thisValidator(value)
    }
}

export const getPTWFormFieldsConfig = ({ data, canWrite, permitType, currentUser, formValuesRef }: GetPTWFormFieldsConfigProps): FieldsFormConfig => {
    // NB ANY CHANGES HERE SHOULD ALSO BE MADE IN THE CORRESPONDING BACK END 'OPEN' SIGNATURE FORM.  TODO - AT SOME POINT WE SHOULD PROBABLY LOOK
    // TO CONNECT THEM AND DERIVE THE ONE FROM THE OTHER.
    let config;
    const today = moment().startOf('day').toDate();
    const thisScheduledFor = moment(data.visit_scheduled_for).startOf('day');

    const fetchPermittedPermitStaffOptions = (params: SearchParams, callback: (results: Option[]) => any) => {
        const theseParams = {
            ...params,
            ptw_type: permitType
        }
        searchForPermittedPermitStaff(data.id, theseParams).then((response) => {
            let resultsData = response.results?.data;
            callback(resultsData);
        })
    };

    const dateInductionReceivedConfig = {
        fieldValidator: ({ formValuesRef, value, meta }: any) => {
            let validations: Dictionary<string> = {};
            let permitStartDate = moment(data.start_time);
            const twoYearsAgo = permitStartDate.subtract(2, "years");//.toDate();

            if (value) {
                const inductionReceived = moment(value as string);
                if (twoYearsAgo > inductionReceived) {
                    const inducationDateValidationMessage = `The induction date should be within two years of the scheduled works date (after ${twoYearsAgo})`
                    validations['inputValidations'] = validations['save'] = validations['submit'] = inducationDateValidationMessage;
                }

                if (inductionReceived.isAfter(data.start_time, 'day')) {
                    const inducationDateValidationMessage = `The induction date cannot be later than the permit start date`
                    validations['inputValidations'] = validations['save'] = validations['submit'] = inducationDateValidationMessage;
                }

            }
            return validations
        },
        // NB we don't currently set a min or max, instead we trigger a validation warning
        // when the date chosen lies outside the values - this is because if we set a range
        // nothing at all happens when a value is input manually that isn't a 'valid' option
        // getMinDate: (meta: FieldMeta) => {
        //     const visitDate = moment(data.visit_scheduled_for);
        //     const twoYearsAgo = visitDate.subtract(2, "years");
        //     return twoYearsAgo.toDate();
        // },
        // getMaxDate: (meta: FieldMeta) => {
        //     return moment().toDate();
        // },
    }
    // const job_title_choices = currentUser.meta?.job_title?.choices;
    // const current_user_job_title_id = currentUser.data.job_title;
    // const current_user_job_title_choice = job_title_choices.filter((x: any) => x.value === current_user_job_title_id);
    // console.log('job title choices: ', currentUser.meta.job_title.choices);
    // console.log('jt id: ', current_user_job_title_id);
    // const current_user_job_title_string = current_user_job_title_choice.length ? current_user_job_title_choice[0].display_name : undefined;
    const coreConfig: FieldsFormConfig = {
        reference: {},
        agreement: {
            ignoreMissing: true
        },
        // Authorisation Personnel
        start_time: {
            addColonToLabel: true,
            fieldValidator: ({ formValuesRef, value, meta }) => {
                const chosenMoment = moment(value?.toString());
                const chosenMomentDate = chosenMoment.startOf('day').toDate();
                const validator = () => {
                    if (chosenMoment.toDate() < thisScheduledFor.toDate()) {
                        return "The permit may not start before the visit scheduled date"
                    } else if (chosenMomentDate < today) {
                        return "A permit cannot be created or issued for a date in the past"
                    }
                }
                return {
                    "inputValidations": validator(),
                    //"save": validator(),
                    "submit": validator()
                }
            },
        },
        finish_time: {
            addColonToLabel: true,
            fieldValidator: ({ formValuesRef, value, meta }) => {
                const now = moment();
                const chosenMoment = moment(value?.toString());
                const st = formValuesRef.current['start_time'];
                let startDateTime = (st !== undefined && st !== null) ? moment(st?.toString()) : st;
                let duration: null | number = null;
                if (value && startDateTime) {
                    duration = moment.duration(chosenMoment.diff(startDateTime)).asHours(); // asHours will take account of intervening days, hours will not
                }
                const validator = () => {
                    if (duration !== null && duration < 1) {
                        return "The permit may not finish less than one hour after the start time"
                    } else if (duration !== null && duration > 24 && (formValuesRef.current?.ptw_type === "Confined Space" || permitType === "Confined Space")) {
                        return `A confined spaces permit cannot be created for a duration greater than 24 hours`
                    } else if (duration !== null && duration > 120) {
                        return `A permit cannot be created for a duration greater than 5 days (120 hours)`
                    } else if (now > chosenMoment) {
                        // NB the string cannot contain ${now} because that would mean the error would keep changing which would trigger reevaluation
                        // on each keystroke once a second has elapsed.  Not an infinite loop, but still unreasonable
                        return `A permit cannot be created or issued after it is set to expire`
                    }
                }
                return {
                    "inputValidations": validator(),
                    //"save": validator(),
                    "submit": validator()
                }
            },
        },
        supervisor_first_name: {
            addColonToLabel: true
        },
        supervisor_last_name: {
            addColonToLabel: true
        },
        supervisor_position: {
            addColonToLabel: true
        },
        supervisor_email: {
            addColonToLabel: true
        },
        supervisor_contact_number: {
            addColonToLabel: true
        },
        //swap the authorised peron fields for autocalculator if you want to switch the user
        // when a person who can edit opens the permit
        jbs_authorised_person_first_name: {
            addColonToLabel: true,
            disabled: true,
            defaultValue: currentUser.data.first_name
        },
        jbs_authorised_person_last_name: {
            addColonToLabel: true,
            disabled: true,
            defaultValue: currentUser.data.last_name
        },
        jbs_authorised_person_position: {
            addColonToLabel: true,
            disabled: true,
            defaultValue: currentUser.data.job_title_display
        },
        jbs_authorised_person_email: {
            addColonToLabel: true,
            disabled: true,
            defaultValue: currentUser.data.email
        },
        jbs_authorised_person: {
            defaultValue: currentUser.data.id,
            disabled: true,
            hidden: true,
        },
        //jbs_authorised_person: {
        //controlClassName: 'full-height',
        //typeOverride: 'autoComplete',
        //freeSolo: false,
        //getOptionLabel: getPermittedStaffLabel,
        //getOptionValue: getPermittedStaffValue,
        //fetchAutoCompleteOptions: canWrite ? fetchPermittedPermitStaffOptions : undefined,
        // sideEffect: (values, fieldConfigs, onChangeFormValues, previousValues) => {
        //     let changed = false;
        //     if (values?.jbs_authorised_person?.id !== previousValues?.jbs_authorised_person?.id) {
        //         if (formValuesRef.current.jbs_authorised_person_email !== values.jbs_authorised_person?.email) {
        //             formValuesRef.current.jbs_authorised_person_email = values.jbs_authorised_person?.email
        //             changed = true;
        //         }
        //         if (formValuesRef.current.jbs_authorised_person_first_name !== values.jbs_authorised_person?.first_name) {
        //             formValuesRef.current.jbs_authorised_person_first_name = values.jbs_authorised_person?.first_name
        //             changed = true;
        //         }
        //         if (formValuesRef.current.jbs_authorised_person_last_name !== values.jbs_authorised_person?.last_name) {
        //             formValuesRef.current.jbs_authorised_person_last_name = values.jbs_authorised_person?.last_name
        //             changed = true;
        //         }
        //         if (formValuesRef.current.jbs_authorised_person_position !== values.jbs_authorised_person?.position) {
        //             formValuesRef.current.jbs_authorised_person_position = values.jbs_authorised_person?.position
        //             changed = true;
        //         }
        //     }
        //     return changed
        // }
        //},
        assessment_confirmation: {
            useRadioButtons: true,
            fieldValidator: ({ formValuesRef, value, meta }) => {
                let npe = formValuesRef.current['nominated_person_email'] as string;
                return {
                    "inputValidations": (!value || value === 0) ? "Have you as the authorised person assessed the risk of this task?: must be positive to save this permit." : undefined,
                    "save": (!value || value === 0) ? "Have you as the authorised person assessed the risk of this task?: must be positive to save this permit." : undefined,
                    //"submit": (value === false || value === 0) || (npe?.length && value === undefined) ? "You must assess the risks to complete this permit." : undefined
                }
            },
        },
        high_risk: {
            useRadioButtons: true,
            autoCalculator: permitType == "Confined Space" || permitType == "Hot Works" ? () => true : undefined,
            hidden: permitType == "Confined Space" || permitType == "Hot Works",
            sideEffect: (values, fieldConfigs, onChangeFormValues, previousValues) => {
                let changed = false;
                if (!values['high_risk']) {
                    if (fieldConfigs['nominated_person_first_name'].disabled) {
                        fieldConfigs['nominated_person_first_name'].disabled = false;
                        fieldConfigs['nominated_person_last_name'].disabled = false;
                        fieldConfigs['nominated_person_position'].disabled = false;
                        fieldConfigs['nominated_person_email'].disabled = false;
                        fieldConfigs['nominated_person'].hidden = true;
                        changed = true;
                    }
                } else {
                    if (!fieldConfigs['nominated_person_first_name'].disabled) {
                        fieldConfigs['nominated_person_first_name'].disabled = true;
                        //values['nominated_person_first_name'] = null;
                        fieldConfigs['nominated_person_last_name'].disabled = true;
                        //values['nominated_person_last_name'] = null;
                        fieldConfigs['nominated_person_position'].disabled = true;
                        //values['nominated_person_position'] = null;
                        fieldConfigs['nominated_person_email'].disabled = true;
                        //values['nominated_person_email'] = null;
                        fieldConfigs['nominated_person'].hidden = false;
                        changed = true;
                    }
                }
                return changed;
            }
        },
        nominated_person_first_name: {
            addColonToLabel: true,
            disabled: true,
            fieldValidator: validateNominatedPersonFieldTogether
        },
        nominated_person_last_name: {
            addColonToLabel: true,
            disabled: true,
            fieldValidator: validateNominatedPersonFieldTogether
        },
        nominated_person_position: {
            addColonToLabel: true,
            disabled: true,
            fieldValidator: validateNominatedPersonFieldTogether
        },
        nominated_person_email: {
            addColonToLabel: true,
            disabled: true,
            fieldValidator: validateNominatedPersonFieldTogether
        },
        nominated_person_contact_number: {
            addColonToLabel: true
        },
        nominated_person: {
            controlClassName: 'full-height',
            typeOverride: 'autoComplete',
            freeSolo: false,
            getOptionLabel: getPermittedStaffLabel,
            getOptionValue: getPermittedStaffValue,
            //getOptionSelected: getPermittedStaffOptionSelected,
            fetchAutoCompleteOptions: canWrite ? fetchPermittedPermitStaffOptions : undefined,
            sideEffect: (values, fieldConfigs, onChangeFormValues, previousValues) => {
                let changed = false;
                if (values?.nominated_person && values?.nominated_person?.id !== previousValues?.nominated_person?.id) {
                    if (formValuesRef.current.nominated_person_email !== values.nominated_person?.email) {
                        formValuesRef.current.nominated_person_email = values.nominated_person?.email
                        changed = true;
                    }
                    if (formValuesRef.current.nominated_person_first_name !== values.nominated_person?.first_name) {
                        formValuesRef.current.nominated_person_first_name = values.nominated_person?.first_name
                        changed = true;
                    }
                    if (formValuesRef.current.nominated_person_last_name !== values.nominated_person?.last_name) {
                        formValuesRef.current.nominated_person_last_name = values.nominated_person?.last_name
                        changed = true;
                    }
                    if (formValuesRef.current.nominated_person_position !== values.nominated_person?.position) {
                        formValuesRef.current.nominated_person_position = values.nominated_person?.position
                        changed = true;
                    }
                    // contact number field seems more fragile (it was sometimes being set to blank on/after save) 
                    // probably because it can be written to when a nominated person is selected, unlike the other fields above
                    // so carrying out a different check
                    if (values?.nominated_person?.mobile != previousValues?.nominated_person?.mobile) {
                        formValuesRef.current.nominated_person_contact_number = values.nominated_person?.mobile;
                        changed = true;
                    }
                    // if (formValuesRef.current.nominated_person_contact_number !== values.nominated_person?.mobile) {
                    //     formValuesRef.current.nominated_person_contact_number = values.nominated_person?.mobile
                    //     changed = true;
                    // }
                }
                return changed
            }
        },
        // Safe Systems of Work
        // risk_assessment_document_number: {
        //     addColonToLabel: true
        // },
        // risk_assessment_author: {
        //     addColonToLabel: true
        // },
        // risk_assessment_not_uploaded_reason: {
        //     skipForm: !data.risk_assessment_missing,
        // },
        // method_statement_document_number: {
        //     addColonToLabel: true
        // },
        // method_statement_author: {
        //     addColonToLabel: true
        // },
        // method_statement_not_uploaded_reason: {
        //     skipForm: !data.method_statement_missing,
        // },

        date_induction_received: dateInductionReceivedConfig,
        communication_systems: {
            useRadioButtons: true,
            sideEffect: (values: FormValues, fieldConfigs: FieldsFormConfig) => {
                // recipent name disabling control
                let changed = false;
                const thisValue = values['communication_systems'];
                const communicationSystemsDetailsConfig = fieldConfigs['communication_systems_details']
                if (communicationSystemsDetailsConfig !== undefined) {
                    if (thisValue) {
                        if (communicationSystemsDetailsConfig.skipForm) {
                            communicationSystemsDetailsConfig.skipForm = false;
                            changed = true;
                        }
                    } else {
                        if (!communicationSystemsDetailsConfig.skipForm) {
                            communicationSystemsDetailsConfig.skipForm = true;
                            changed = true;
                        }
                    }
                }
                return changed;
            },

        },
        communication_systems_details: { addColonToLabel: true },

        // Documentation
        attendance_register_signed: {
            useRadioButtons: true,
        },
        induction_given: {
            useRadioButtons: true,
            sideEffect: (values: FormValues, fieldConfigs: FieldsFormConfig) => {
                // recipent name disabling control
                let changed = false;
                const thisValue = values['induction_given'];
                const dateInductionReceivedConfig = fieldConfigs['date_induction_received']
                if (dateInductionReceivedConfig !== undefined) {
                    if (thisValue) {
                        if (dateInductionReceivedConfig.skipForm) {
                            dateInductionReceivedConfig.skipForm = false;
                            changed = true;
                        }
                    } else {
                        if (!dateInductionReceivedConfig.skipForm) {
                            dateInductionReceivedConfig.skipForm = true;
                            changed = true;
                        }
                    }
                }
                return changed;
            },
        },
        rams_reviewed: {
            useRadioButtons: true,
        },
        workers_informed_of_local_hazards: {
            useRadioButtons: true,
        },
        relevant_ppe_checked: { useRadioButtons: true, },
    }
    switch (permitType) {
        case "Hot Works": {
            config = {
                ...coreConfig,
                description: {
                    multiline: true,
                    rows: 3,
                    label: "Hot works location and description, including any plant or processes",
                    addColonToLabel: true
                },
                client_permission_granted: {
                    useRadioButtons: true,
                },
                fire_detection_systems_will_be_affected: {
                    useRadioButtons: true,
                },
                suppression_systems_will_be_affected: {
                    useRadioButtons: true,
                },
                // Description of Isolations
                isolation_comments: {
                    multiline: true,
                    rows: 2,
                    addColonToLabel: true
                },
                // Control measures which must be in place before issuing a Permit to work - Hot Work
                workers_informed_of_local_hazards: {
                    useRadioButtons: true,
                },
                relevant_ppe_checked: {
                    useRadioButtons: true,
                },
                combustibles_removed: {
                    useRadioButtons: true,
                },
                combustibles_protected: {
                    useRadioButtons: true
                },
                equipment_certification_submitted: {
                    useRadioButtons: true,
                },
                area_protected_from_fire: {
                    useRadioButtons: true,
                },
                firefighting_equipment_available: {
                    useRadioButtons: true,
                },
                fire_watch_to_be_maintained: {
                    useRadioButtons: true,
                },
                // Primary Hazards - Fire and Explosion
                fire_alarm_can_be_sounded: {
                    useRadioButtons: true,
                },
                surfaces_protected: {
                    useRadioButtons: true,
                },
                vessels_or_lines_protected_and_flammables_free: {
                    useRadioButtons: true,
                },
                area_to_be_wetted_or_fire_blankets_used: {
                    useRadioButtons: true,
                },
                ppe_required: {
                    multiline: true,
                    rows: 2,
                    addColonToLabel: true
                },
                other_precautions: {
                    multiline: true,
                    rows: 3,
                    addColonToLabel: true,
                }
            }
        }
            break;
        case "Confined Space": {
            config = {
                ...coreConfig,
                //------
                // nominated_person_first_name: {
                //     addColonToLabel: true,
                //     disabled: true,
                //     defaultValue: currentUser.data.first_name
                // },
                // nominated_person_last_name: {
                //     addColonToLabel: true,
                //     disabled: true,
                //     defaultValue: currentUser.data.last_name
                // },
                // nominated_person_position: {
                //     addColonToLabel: true,
                //     disabled: true,
                //     defaultValue: currentUser.data.job_title_display
                // },
                // nominated_person_email: {
                //     addColonToLabel: true,
                //     disabled: true,
                //     defaultValue: currentUser.data.email
                // },
                // nominated_person: {
                //     defaultValue: currentUser.data.id,
                //     disabled: true,
                //     hidden: true,
                // },
                //-----
                description: {
                    label: "Confined space location and description, including any plant or processes",
                    addColonToLabel: true
                },
                type_of_confined_space_and_specified_risks: {
                    addColonToLabel: true
                },
                //Safe system of work
                // rescue_plan_document_number: {
                //     addColonToLabel: true
                // },
                // rescue_plan_author: {
                //     addColonToLabel: true
                // },
                // rescue_plan_not_uploaded_reason: {
                //     skipForm: !data.rescue_plan_missing,
                // },
                // hazards
                acceptable_access: { useRadioButtons: true },
                isolated_from_pipework: { useRadioButtons: true, },
                space_purged: { useRadioButtons: true, },
                electrically_and_mechanically_isolated: { useRadioButtons: true, },
                temperature_maintainable: { useRadioButtons: true, },
                breathing_apparatus_checked: { useRadioButtons: true, },
                backup_equipment_checked: { useRadioButtons: true, },
                emergency_arrangements_in_place: { useRadioButtons: true, },
                atmospheric_testing_required: { useRadioButtons: true, },
                respirable_air_assured: { useRadioButtons: true, },
                atmospheric_testing_unit_info: {
                    addColonToLabel: true
                },
                atmospheric_testing_calibration_date: {
                    getMaxDate: (meta: FieldMeta) => {
                        return moment().toDate();
                    }
                },
                residual_hazards: {
                    addColonToLabel: true
                },
                worker_competences_checked: { useRadioButtons: true, },
            }
        }
            break;
        case "Limitation of Access": {
            config = {
                ...coreConfig,
                description: {
                    label: "Authorised area location and description, including any plant or processes",
                    addColonToLabel: true,
                },
                // Documentation
                escort_required: { useRadioButtons: true, },
                supervision_required: { useRadioButtons: true, },
                // Hazards. Have the following potential hazards been considered:
                access: { useRadioButtons: true, },
                egress: { useRadioButtons: true, },
                safe_routes: { useRadioButtons: true, },
                additional_information: {
                    addColonToLabel: true
                },
                // Safe Systems of Work
                signs_and_barriers: { useRadioButtons: true, },
                site_specific_rules_communicated: { useRadioButtons: true },
            }
        }
            break;
        case "Working At Height": {
            config = {
                ...coreConfig,
                description: {
                    label: "Working at height location and description",
                    addColonToLabel: true
                },
                // SSOW
                signs_and_barriers: { useRadioButtons: true, },
                signs_and_barriers_in_place: { useRadioButtons: true, },
                site_specific_rules_communicated: { useRadioButtons: true },
                activities: {
                    addColonToLabel: true
                },
                // Documentation
                // Hazards. Have the following potential hazards been considered:
                qualified: {
                    useRadioButtons: true,
                    fieldValidator: ({ formValuesRef, value, meta }: any) => {
                        return {
                            "inputValidations": (value === false || value === 0) ? "All persons involved must be qualified to use the WAH equipment " : undefined
                        }
                    }
                },
                boards_handrails_ladders: { useRadioButtons: true, },
                scaffolding_and_arrest_systems: { useRadioButtons: true, },
                scaffolding_inspected_and_tagged: { useRadioButtons: true, },
                harness_inspected: { useRadioButtons: true, },
                harness_lanyard_certificate_provided: { useRadioButtons: true, },
                access_equipment_inspected: { useRadioButtons: true, },
                weather_conditions: { useRadioButtons: true, },
                ducts_and_outlets: {
                    useRadioButtons: true,
                    sideEffect: (values: FormValues, fieldConfigs: FieldsFormConfig) => {
                        // recipent name disabling control
                        let changed = false;
                        const thisValue = values['ducts_and_outlets'];
                        if (thisValue && typeof thisValue === "string" && thisValue?.toLowerCase() === 'yes') {
                            if (fieldConfigs['fume_hazards'].skipForm) {
                                fieldConfigs['fume_hazards'].skipForm = false;
                                changed = true;
                            }
                        } else {
                            if (!fieldConfigs['fume_hazards'].skipForm) {
                                fieldConfigs['fume_hazards'].skipForm = true;
                                changed = true;
                            }
                        }
                        return changed;
                    },
                },
                fume_hazards: { useRadioButtons: true, },
                falling_objects_risk: { useRadioButtons: true, },
                equipment_tethered: { useRadioButtons: true, },
                overhead_services: { useRadioButtons: true, },
                edge_protection: { useRadioButtons: true, },
                guard_rails: { useRadioButtons: true, },
                ppe_required: {
                    addColonToLabel: true
                },
            }
        }
            break;
        default: {
            config = coreConfig;
        }
    }
    return config;
}

type PermitData = BasePermitToWork | HotWorksPTW | WorkingAtHeightPTW | LimitationOfAccessPTW | ConfinedSpacesPTW;

interface PTWFormLayoutProps {
    permitType: WorkPermitType;
    // RenderDescriptionOfIsolations?: () => JSX.Element;
    // RenderPTWTrainingCourses: () => JSX.Element;
    // RenderRoles: () => JSX.Element;
    RenderHotWorksIsolationSystems: () => JSX.Element;
    RenderPermitSupportingDocsPicker: () => JSX.Element;
    RenderPermitTypePicker: () => JSX.Element | undefined;
    RenderPermitRef: () => JSX.Element | undefined;
    data: PermitData;
}

const standardAuthorisedFieldsChildren = [
    {
        fields: [
            "jbs_authorised_person",
            "jbs_authorised_person_first_name",
            "jbs_authorised_person_last_name",
            "jbs_authorised_person_position",
            "jbs_authorised_person_email",
        ],
        group_title: "JBS Authorised Person",
        group_id: "JBSAuthorisedPerson",
        className: "JBSAuthorisedPerson",
    },
    {
        fields: [
            "assessment_confirmation",
            "high_risk",
            "nominated_person",
            "nominated_person_first_name",
            "nominated_person_last_name",
            "nominated_person_position",
            "nominated_person_email",
            "nominated_person_contact_number"
        ],
        group_title: "Nominated Person",
        group_subtitle: "Significant high-risk tasks will require the supervision of trained and competent authorised person. If a task is low risk a non-trained operative can be nominated to supervise activities only.",
        group_id: "NominatedPerson",
        className: "NominatedPerson",
    },
    {
        fields: [
            "supervisor_first_name",
            "supervisor_last_name",
            "supervisor_position",
            "supervisor_email",
            "supervisor_contact_number"
        ],
        group_title: "Works Supervisor",
        group_id: "Supervisor",
        className: "Supervisor",
    },
]

const confinedSpacesAuthorisedFieldsChildren = standardAuthorisedFieldsChildren.map(a => ({ ...a }));

const hotWorksAuthorisedFieldsChildren = standardAuthorisedFieldsChildren.map(a => ({ ...a }));

confinedSpacesAuthorisedFieldsChildren[1].group_subtitle = "Confined space tasks are always considered high risk and they will require the supervision of trained and competent personnel";
hotWorksAuthorisedFieldsChildren[1].group_subtitle = "Hot works are always considered high risk and they will require the supervision of trained and competent personnel";


const getGeneralPTWFieldLookups = (data: PermitData, permitType: WorkPermitType): Dictionary<FieldGroup> => {
    //@ts-ignore
    const safeSystemOfWorkFields = [
        "risk_assessment_document_number",
        "risk_assessment_author",
        // ...data.risk_assessment_missing ? ["risk_assessment_not_uploaded_reason"] : [],
        "method_statement_document_number",
        "method_statement_author",
        // ...data.method_statement_missing ? ["method_statement_not_uploaded_reason"] : [],
        ...permitType === "Confined Space" ? ["rescue_plan_document_number"] : [],
        ...permitType === "Confined Space" ? ["rescue_plan_author"] : [],
        // ...data.rescue_plan_missing && permitType === "Confined Space" ? ["rescue_plan_not_uploaded_reason"] : [],
        "rams_reviewed",
        "workers_informed_of_local_hazards",
        "relevant_ppe_checked",
        "communication_systems",
        "communication_systems_details"
    ]
    return {
        authorisedSection: {
            fields: [
                // Authorisation by Authorised Person
                // "date",
                "start_time",
                "finish_time",
                "description",
            ],
            group_title: "Authorisation by Authorised Person",
            group_id: "Authorisation by Authorised Person",
            className: "authorisation",
            children: standardAuthorisedFieldsChildren
        },
        safeSystemsOfWorkSection: {
            fields: safeSystemOfWorkFields,
            group_title: "Safe Systems of Work",
            group_id: "Safe Systems of Work",
            className: `safeSystemsOfWork`,
        },
        documentationSection: {
            fields: [
                "attendance_register_signed",
                "induction_given",
                "date_induction_received",
            ],
            group_title: "Documentation",
            group_id: "Documentation",
            className: "documentation",
            // children: [
            //     {
            //         fields: [
            //             "keys_issued",
            //             "key_recipient_name",
            //             "date_keys_issued",
            //         ],
            //         group_title: "Keys",
            //         group_id: "keysIssued",
            //         className: "keysIssued",

            //     }
            // ]
        },
        controlMeasuresSection: {
            fields: [
                "combustibles_removed",
                "combustibles_protected",
                "area_protected_from_fire",
                "firefighting_equipment_available",
                "fire_watch_to_be_maintained",
            ],
            group_title: "Control Measures",
            group_id: "Control Measures",
            className: "controlMeasures",
        }
    }
}

export const useGetPTWFormLayout = ({
    data,
    permitType,
    // RenderPTWTrainingCourses, 
    // RenderRoles, 
    // RenderDescriptionOfIsolations, 
    //RenderHotWorksIsolationSystems,
    RenderPermitSupportingDocsPicker,
    RenderPermitTypePicker,
    RenderPermitRef
}: PTWFormLayoutProps) => {
    let layout;
    // we modify a copy of all generalPTWFieldLookups so that if the method is called more than once fields aren't added several times...
    const generalPTWFieldLookups = getGeneralPTWFieldLookups(data, permitType);
    let authorisedSection = { ...generalPTWFieldLookups['authorisedSection'] };
    authorisedSection.children = authorisedSection.children || [];

    let safeSystemsOfWorkSection = { ...generalPTWFieldLookups['safeSystemsOfWorkSection'] };
    let documentationSection = { ...generalPTWFieldLookups['documentationSection'] };
    let controlMeasuresSection = { ...generalPTWFieldLookups['controlMeasuresSection'] };

    const permitTypePicker = {
        fields: [],
        group_title: "",
        group_id: "PermitTypePicker",
        className: "permitTypePicker",
        component: RenderPermitTypePicker
    }

    const permitRef = {
        fields: [],
        group_title: "",
        group_id: "PermitRef",
        className: "permitRef",
        component: RenderPermitRef
    }

    const permitTypeWithoutSpaces = permitType ? permitType.replaceAll(" ", '') : 'noPermitType';

    switch (permitType) {
        case "Hot Works": {
            authorisedSection.children = hotWorksAuthorisedFieldsChildren;
            authorisedSection.fields = [
                ...authorisedSection.fields,
                "client_permission_granted",
                "fire_detection_systems_will_be_affected",
                "suppression_systems_will_be_affected",
                "isolation_comments",
            ]
            safeSystemsOfWorkSection.fields = [
                ...safeSystemsOfWorkSection.fields,
                RenderPermitSupportingDocsPicker,
                //RenderHotWorksIsolationSystems
            ]
            documentationSection.fields = [
                ...documentationSection.fields,
                "equipment_certification_submitted",
            ]
            documentationSection.className = `${documentationSection.className} ${permitTypeWithoutSpaces}Group`;
            safeSystemsOfWorkSection.className = `${safeSystemsOfWorkSection.className} ${permitTypeWithoutSpaces}Group`;

            // const descriptionOfIsolationsSection = {
            //     fields: [],
            //     group_title: "",
            //     group_id: "RenderDescriptionOfIsolations",
            //     className: "isolations",
            //     component: RenderDescriptionOfIsolations
            // }

            let primaryHazardsSection = {
                fields: [
                    "fire_alarm_can_be_sounded",
                    "surfaces_protected",
                    "vessels_or_lines_protected_and_flammables_free",
                    "area_to_be_wetted_or_fire_blankets_used",
                    "ppe_required",
                    "other_precautions"
                ],
                group_title: "Primary Hazards - Fire and Explosion",
                group_id: `PrimaryHazards${permitTypeWithoutSpaces}Group`,
                className: `primaryHazards ${permitTypeWithoutSpaces}Group`,
            }

            layout = [
                permitTypePicker,
                permitRef,
                authorisedSection,
                safeSystemsOfWorkSection,
                documentationSection,
                controlMeasuresSection,
                primaryHazardsSection,
                //descriptionOfIsolationsSection,
            ]

        }
            break;
        case "Working At Height": {
            authorisedSection.fields = [
                ...authorisedSection.fields,
            ]

            let primaryHazardsSection = {
                fields: [
                    "qualified",
                    "boards_handrails_ladders",
                    "scaffolding_and_arrest_systems",
                    "scaffolding_inspected_and_tagged",
                    "harness_inspected",
                    "harness_lanyard_certificate_provided",
                    "access_equipment_inspected",
                    "weather_conditions",
                    "ducts_and_outlets",
                    "fume_hazards",
                    "falling_objects_risk",
                    "equipment_tethered",
                    "overhead_services",
                    "edge_protection",
                    "guard_rails",
                    "ppe_required",
                ],
                group_title: "Hazards",
                group_id: `PrimaryHazards${permitTypeWithoutSpaces}Group`,
                className: `primaryHazards ${permitTypeWithoutSpaces}Group`,
            }

            safeSystemsOfWorkSection.fields = [
                ...safeSystemsOfWorkSection.fields,
                "signs_and_barriers",
                "signs_and_barriers_in_place",
                "site_specific_rules_communicated",
                "activities",
                RenderPermitSupportingDocsPicker,
            ]

            safeSystemsOfWorkSection.className = `${safeSystemsOfWorkSection.className} ${permitTypeWithoutSpaces}Group`;

            documentationSection.className = `${documentationSection.className} ${permitTypeWithoutSpaces}Group`;

            layout = [
                permitTypePicker,
                permitRef,
                authorisedSection,
                safeSystemsOfWorkSection,
                documentationSection,
                primaryHazardsSection,
            ]

        }
            break;
        case "Limitation of Access": {
            authorisedSection.fields = [
                ...authorisedSection.fields,
            ]
            // Documentation
            documentationSection.fields = [
                ...documentationSection.fields,
                "escort_required",
                "supervision_required",
            ]

            documentationSection.className = `${documentationSection.className} ${permitTypeWithoutSpaces}Group`;

            // Safe Systems of Work

            safeSystemsOfWorkSection.fields = [
                ...safeSystemsOfWorkSection.fields,
                "signs_and_barriers",
                "site_specific_rules_communicated",
                RenderPermitSupportingDocsPicker,
            ]

            safeSystemsOfWorkSection.className = `${safeSystemsOfWorkSection.className} ${permitTypeWithoutSpaces}Group`;


            // Hazards. Have the following potential hazards been considered:
            let primaryHazardsSection = {
                fields: [
                    "access",
                    "egress",
                    "safe_routes",
                    "additional_information",
                ],
                group_title: "Hazards",
                group_subtitle: "Have the following potential hazards been considered?",
                group_id: `PrimaryHazards${permitTypeWithoutSpaces}Group`,
                className: `primaryHazards ${permitTypeWithoutSpaces}Group`,
            }

            layout = [
                permitTypePicker,
                permitRef,
                authorisedSection,
                safeSystemsOfWorkSection,
                documentationSection,
                primaryHazardsSection,
            ]

        }
            break;

        case "Confined Space": {
            authorisedSection.children = confinedSpacesAuthorisedFieldsChildren;
            authorisedSection.fields = [
                ...authorisedSection.fields,
                "type_of_confined_space_and_specified_risks",

            ]
            authorisedSection.className = `${authorisedSection.className} ${permitTypeWithoutSpaces}Group`;

            // Safe Systems of Work

            safeSystemsOfWorkSection.fields = [
                ...safeSystemsOfWorkSection.fields,
                "worker_competences_checked",
                // RenderPTWTrainingCourses,
                // RenderRoles,
                RenderPermitSupportingDocsPicker
            ]

            safeSystemsOfWorkSection.className = `${safeSystemsOfWorkSection.className} ${permitTypeWithoutSpaces}Group`;


            // Hazards. Have the following potential hazards been considered:
            let primaryHazardsSection = {
                fields: [
                    "acceptable_access",
                    "isolated_from_pipework",
                    "space_purged",
                    "electrically_and_mechanically_isolated",
                    "temperature_maintainable",
                    "breathing_apparatus_checked",
                    "backup_equipment_checked",
                    "emergency_arrangements_in_place",
                    "atmospheric_testing_required",
                    "respirable_air_assured",
                    "atmospheric_testing_unit_info",
                    "atmospheric_testing_calibration_date",
                    "residual_hazards",
                ],
                group_title: "Hazards",
                group_id: `PrimaryHazards${permitTypeWithoutSpaces}Group`,
                className: `primaryHazards ${permitTypeWithoutSpaces}Group`,
            }

            layout = [
                permitTypePicker,
                permitRef,
                authorisedSection,
                safeSystemsOfWorkSection,
                documentationSection,
                primaryHazardsSection,
            ]

        }
            break;


        default: {

            safeSystemsOfWorkSection.fields = [
                ...safeSystemsOfWorkSection.fields,
                RenderPermitSupportingDocsPicker
            ]

            layout = [
                permitTypePicker,
                permitRef,
                authorisedSection,
                safeSystemsOfWorkSection,
                documentationSection,
            ]
        }

    }
    return layout;
};
